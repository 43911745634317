import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings';
import {ISettingsParams} from '../../domain/utils/settings.utils';

export type ISideCartPublicData = Partial<{[k in keyof ISettingsParams<typeof settingsParams>]: string | {}}>;

export const settingsParams = {
  CART_TITLE: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('sideCart.title.label'),
    inheritFromAppScope: true,
  },
  CART_TITLE_VISIBILITY: {
    key: 'CART_TITLE_VISIBILITY',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_NUMBER_OF_CART_ITEMS_VISIBILITY: {
    key: 'CART_NUMBER_OF_CART_ITEMS_VISIBILITY',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_ITEM_QUANTITY_COUNTER_VISIBILITY: {
    key: 'CART_ITEM_QUANTITY_COUNTER_VISIBILITY',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_ITEM_IMAGE_VISIBILITY: {
    key: 'CART_ITEM_IMAGE_VISIBILITY',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_ITEM_INFO: {
    key: 'CART_ITEM_INFO',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_SUMMARY_DISCLAIMER_VISIBILITY: {
    key: 'CART_SUMMARY_DISCLAIMER_VISIBILITY',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_SUMMARY_SECURE_BADGE_VISIBILITY: {
    key: 'CART_SUMMARY_SECURE_BADGE_VISIBILITY',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY: {
    key: 'CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY: {
    key: 'CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  CART_EMPTY_CART: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('sideCart.cartEmpty.emptyState'),
  },
  CART_BUTTON_TEXT: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('sideCart.goToCheckout.cta'),
    inheritFromAppScope: true,
  },
};
export default createSettingsParams<ISettingsParams<typeof settingsParams>>(settingsParams);
