import React from 'react';
import {useSettings} from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import {Text, TextPriority, IconButton} from 'wix-ui-tpa';
import {Close} from '@wix/wix-ui-icons-common/on-stage';
import {classes} from './Header.st.css';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {useTranslation} from '@wix/yoshi-flow-editor';

export enum HeaderDataHooks {
  title = 'Header.Title',
  itemsCountText = 'Header.itemsCountText',
  closeButton = 'Header.closeButton',
}

export const Header = () => {
  const {get: getSettings} = useSettings();
  const {itemsCount} = useControllerProps().cartStore;
  const {closeSideCart} = useControllerProps().navigationStore;
  const {t} = useTranslation();
  const showTitle = getSettings(settingsParams.CART_TITLE_VISIBILITY) as boolean;
  const showItemsCount = getSettings(settingsParams.CART_NUMBER_OF_CART_ITEMS_VISIBILITY) as boolean;

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <Text tagName={'h2'} priority={TextPriority.primary} className={classes.headerText}>
          {showTitle && (
            <>
              <Text data-hook={HeaderDataHooks.title} className={classes.headerTitle}>
                {getSettings(settingsParams.CART_TITLE) as string}
              </Text>{' '}
            </>
          )}
          {showItemsCount && (
            <Text data-hook={HeaderDataHooks.itemsCountText} className={classes.headerItemCount}>
              {t('sideCart.title.itemQuantity', {itemQuantity: itemsCount ?? 0})}
            </Text>
          )}
        </Text>
        <IconButton
          className={classes.closeButton}
          data-hook={HeaderDataHooks.closeButton}
          icon={<Close />}
          onClick={() => closeSideCart()}
        />
      </div>
    </div>
  );
};
